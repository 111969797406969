import React from 'react'; 
import { graphql } from 'gatsby'
import { Link } from "gatsby"
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import gsap from "gsap";


class IndexPage extends React.Component {
  componentDidMount() {
    var header = document.querySelector('.masthead');

    function checkHeader() {
      if(document.documentElement.scrollTop < 50) {
        header.classList.add('transparent');
      } else {
        header.classList.remove('transparent');
      }
    }
    
    checkHeader();

    document.addEventListener('scroll', function() { 
      checkHeader();
    });

    var tl = gsap.timeline({paused: true});
    tl.to('.preloader .text', .5, {opacity:1, delay:.25});
    tl.to('.preloader .text', 2, {width: '100vw', ease: "power2.inOut", delay:1});
    tl.to('.preloader', 1, {opacity: 0, duration:1, display:'none'});
    tl.to('.intro-line--one', .75, {opacity: 1, x:0}, "-=1.25");
    tl.to('.intro-line--two', .75, {opacity: 1, x:0}, "-=1");
    tl.play();
  }
  render() {
    const {data} = this.props
    return (
      <Layout page="index">
        <Seo title="Home" />
        <div className="hero-banner mtn50 h-100 m-h80 bg-grey flex align-center m-align-bottom pos-rel bb1">
          <div className="title-area p20 text-white max-750 pos-rel z-2 m-mb80">
            <h1 className="m0 mobile-hide">
              <span className="intro-line--one fade-right inline">{data.allDatoCmsHome.edges[0].node.headlineOne}</span><br/>
              <span className="intro-line--two fade-right inline">{data.allDatoCmsHome.edges[0].node.headlineTwo}</span>
            </h1>
            <h1 className="m0 mobile-show intro-line--one fade-right">
              {data.allDatoCmsHome.edges[0].node.headlineOne} {data.allDatoCmsHome.edges[0].node.headlineTwo}
            </h1>
          </div>
          <GatsbyImage image={data.allDatoCmsHome.edges[0].node.headlineImage.gatsbyImageData} alt="Hero Image" className="bg-image" />
        </div>
        <div className="introduction-copy flex m-wrap">
          <div className="w-50 p20 m-100">
            <p className="h3 m0 text-yellow mt40 fade-right" data-sal data-sal-delay="300">{data.allDatoCmsHome.edges[0].node.introTitle}</p>
          </div>
          <div className="w-50 p20 m-100 fade-in" data-sal data-sal-delay="500">
            <p className="m0 h2 mt40">{data.allDatoCmsHome.edges[0].node.introContent}</p>
          </div>
        </div>

        <div className="spacer m-hide"></div>
        <div className="spacer"></div>

        <div className="our-approach m-mb40">
          <div className="title-area p20">
            <p className="m0 h3 fade-right" data-sal data-sal-delay="300">Our Approach</p>
          </div>
          <div className="p20 flex fade-in m-wrap" data-sal data-sal-delay="500">
            <div className="col text-large w-35 m-100 m-mb40">
              <p className="m0 text-yellow mb20">Identify</p>
              <p className="m0 op-50 max-450">{data.allDatoCmsHome.edges[0].node.identify}</p>
            </div>
            <div className="col text-large w-35 m-100 m-mb40">
              <p className="m0 text-yellow mb20">Tailor</p>
              <p className="m0 op-50 max-450">{data.allDatoCmsHome.edges[0].node.tailor}</p>
            </div>
            <div className="col text-large w-35 m-100">
              <p className="m0 text-yellow mb20">Monitor</p>
              <p className="m0 op-50 max-450">{data.allDatoCmsHome.edges[0].node.monitor}</p>
            </div>
          </div>
          <div className="link p20 fade-in" data-sal data-sal-delay="500">
            <Link to="/approach" className="link with-arrow">Learn More</Link> 
          </div>
          <div className="spacer half m-hide"></div>
        </div>

        <div className="about-section bg-yellow flex mh-80">
          <div className="p20 flex w-100 m-wrap">
            <div className="w-50 m-100">
              <p className="m0 h3 mt20 fade-right" data-sal data-sal-delay="300">About</p>
            </div>
            <div className="w-50 m-100 fade-in" data-sal data-sal-delay="500">
              <div className="h2 mt20 max-700">
                {data.allDatoCmsHome.edges[0].node.aboutGmContent}
              </div>
              <div className="mt40 fade-in" data-sal data-sal-delay="500">
                <Link to="/about" className="link with-arrow">Learn More</Link> 
              </div>
            </div>
          </div>
        </div>

        <div className="image-banner flex flex-wrap align-center mh-80 p20 pos-rel ">
          <div className="title-area p20 text-white w-100 pos-rel z-2 align-bottom">
            <h1 className="m0">
              <span className="fade-right inline" data-sal data-sal-delay="300">{data.allDatoCmsHome.edges[0].node.ctaTitleOne}</span>
              <br/><span className="fade-right inline" data-sal-delay="500" data-sal>{data.allDatoCmsHome.edges[0].node.ctaTitleTwo}</span>
            </h1>
          </div>
          <div className="align-bottom pos-rel z-2 w-100 p20 text-white fade-in" data-sal data-sal-delay="300">
            <Link to="/contact" className="link with-arrow h3">Contact Us</Link> 
          </div>
          <GatsbyImage image={data.allDatoCmsHome.edges[0].node.ctaImage.gatsbyImageData} alt="CTA Image" className="bg-image" />
        </div>
      </Layout>
    )
  }
}

export const indexQuery = graphql`
  query indexQuery {
    allDatoCmsHome {
      edges {
        node {
          headlineOne
          headlineTwo
          headlineImage {
            gatsbyImageData(placeholder:DOMINANT_COLOR)
          }
          introContent
          introTitle
          monitor
          identify
          tailor
          aboutGmContent
          ctaTitleOne
          ctaTitleTwo
          ctaImage {
            gatsbyImageData(placeholder:DOMINANT_COLOR)
          }
        }
      }
    }
  }
`

export default IndexPage